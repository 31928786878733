import React, { useState,useRef } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import { A } from '../components/styles'
import Group from "../components/group"


export default function IndexPage({ pageContext: { node,list },location }) {
    const refList = useRef(null)
    const showMore = (e)=>{
        e.preventDefault()
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 14
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("opacity-30")
            e.target.classList.add("cursor-default")
        }
    }
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                  <h1 className="text-[200px] leading-none pb-[95px] lg:pb-10 pt-[260px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.h1}</h1>
                  <ul className="text-[28px] sm:text-[22px] pb-[54px] font-bold text-[#bbb] flex items-center flex-wrap lg:flex-col lg:items-start">
                      <li><a href="/stories" className="hover:text-black">All</a></li>
                      {
                          list.map((n,i)=>{
                              return <li key={i} className="ml-8 lg:ml-0"><a href={n.url} className={`hover:text-black ${n.url===node.link.url?'text-black':''}`}>{n.title}</a></li>
                          })
                      }
                  </ul>
                  <div className="grid grid-cols-4 gap-x-8 gap-y-14 mb-[95px] grid-list lg:grid-cols-2 sm:grid-cols-1" ref={refList}>
                  {
                      node.stories_details.map((n,i)=>{
                        if(i<8){
                          return(
                            <Group lg={true} key={i} category={node.title} node={n}/>
                          )
                        }
                      })
                  }
                  </div>
                  <A href="#" variant="add" onClick={showMore}>Show more stories</A>
            </div>
        </Layout>
    )
}

